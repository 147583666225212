import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import AppBar from '@mui/material/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
// import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory, useLocation } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCamera,
    faCameraRetro,
    faCloudUploadAlt,
    faDigitalTachograph,
    faFilm,
    faFolderOpen, faHandsHelping, faInfoCircle,
    faProjectDiagram,
    faSearch,
    faSpaceShuttle,
    faTasks,
    faTv,
    faVideo
} from "@fortawesome/free-solid-svg-icons";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import PushPinIcon from '@mui/icons-material/PushPin';
import Button from "@mui/material/Button";
import logBall from "../../img/Kugelweb.svg";
import {
    getPeerconnections,
    getSession,
    logout,
    sessionValid,
    setPeerConnection
} from "../Auth/AuthHandler";
import {
    AccountCircle,
    Add,
    CameraIndoor,
    Drafts,
    Inbox,
    LocalLibrary,
    Notifications,
    NotificationsActive,
    NotificationsOff,
    TabletAndroid, VideoCameraBack
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import GSButton from "../GSHelperComponents/GSButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import PanoramaIcon from '@mui/icons-material/Panorama';
import { blue } from "@mui/material/colors";
import { peerconnectionsStreams } from '../Auth/AuthHandler.js'
import PopupState from "material-ui-popup-state";
import SendIcon from '@mui/icons-material/Send';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import MicIcon from '@mui/icons-material/Mic';
import BadgeIcon from '@mui/icons-material/Badge';
import Crop32Icon from '@mui/icons-material/Crop32';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import GlassSphereContext from "../GlassSphereContext/GlassSphereContext";
import axios from "axios";
import { APIURL } from "../../Views/Routes";
import Grid from "@mui/material/Grid";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import { deviceDetect, isMobile } from 'react-device-detect'
import NotificationDialog, { getPushToken, getWebpush } from "../dialogs/notification/NotificationDialog";
import { v4 as uuidv4 } from "uuid";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import useConfirm from "../dialogs/useConfirm";
import GlassmorphismBackground from '../rxa/comp/GlassMorphism';

const PREFIX = 'ResponsiveDrawer';


const classes = {
    root: `${PREFIX}-root`,
    drawer: `${PREFIX}-drawer`,
    appBar: `${PREFIX}-appBar`,
    menuButton: `${PREFIX}-menuButton`,
    toolbar: `${PREFIX}-toolbar`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    content: `${PREFIX}-content`,
    closeMenuButton: `${PREFIX}-closeMenuButton`
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth, transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
    }), overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), overflowX: 'hidden', width: `calc(${theme.spacing(7)} + 1px)`, [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth, flexShrink: 0, whiteSpace: 'nowrap', boxSizing: 'border-box', ...(open && {
        ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme),
    }), ...(!open && {
        ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme),
    }),
}),);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: theme.spacing(0, 1), // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1, transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function ResponsiveDrawer(props) {

    const { content } = props
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [path, setPath] = useState("");
    const [drawerWidth, setDrawerWith] = useState(50)
    const [anchorEl, setAnchorEl] = useState(null);
    const [account, setAccount] = useState(false);
    const [open, setOpen] = useState(false);
    const [expertModus, setExpertModus] = useState(false)
    const [userLevel, setUserlevel] = useState(getSession()?.levels)
    const [usermenu, setUserMenu] = useState([])
    const { broadcast, loggedIn } = useContext(GlassSphereContext);
    const [localbroadcast, setLocalBroadcast] = broadcast
    const [localloggedIn, setLocalLoggedIn] = loggedIn
    const [notiDialogOpen, setNotiDialogOpen] = useState(false)
    const [notiEnabled, setNotiEnabled] = useState(false)
    const { confirm } = useConfirm()
    const isflutter = typeof window.flutter_inappwebview !== 'undefined'
    // const isflutter = true

    let location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (userLevel) setUserMenu(getMenuForUser())
    }, [userLevel])

    useEffect(() => {
        setPath(location.pathname);
    }, [location, setPath]);

    useEffect(() => {
        setPath(location.pathname);
    }, [location, setPath]);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        setInterval(() => {
            if (sessionValid()) {
                fetch(APIURL().ping, {
                    credentials: 'include'
                }).catch(async (err) => {
                    logout()
                    history.push("/login")
                })
            }
        }, 10000);


        window.addEventListener("focus", onFocus)
        return () => {
            window.removeEventListener("focus", onFocus)
        }

    }, [])
    const receiveAlert = () => alert('hello from JS');
    useEffect(() => {

        if (localloggedIn) {
            console.log("loggeding " + localloggedIn)

            if (isflutter) {
                window.function = receiveAlert
                getPushToken()

            } else {
                getWebpush().then(x => {
                    if (localStorage.getItem("notiuuid"))
                        setNotiEnabled(true)
                })

            }
        }

    }, [localloggedIn])
    useEffect(() => {
        console.log("notification changed:" + notiEnabled)
    }, [notiEnabled])
    const onFocus = () => {
        if (sessionValid()) {
            axios.get(APIURL().ping, { withCredentials: true })
        } else {
            logout()
            history.push("/login")
        }

    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }
    const Root = styled('div')(({ theme }) => ({
        [`& .${classes.root}`]: {
            display: 'flex',

        },

        [`& .${classes.drawer}`]: {

            [theme.breakpoints.up('sm')]: {
                width: drawerWidth, flexShrink: 0,
            },
        },

        [`& .${classes.appBar}`]: {
            zIndex: theme.zIndex.drawer + 1,
        },

        [`& .${classes.menuButton}`]: {
            marginRight: theme.spacing(2), [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },

        [`& .${classes.toolbar}`]: theme.mixins.toolbar,

        [`& .${classes.drawerPaper}`]: {
            width: drawerWidth
        },

        [`& .${classes.content}`]: {
            flexGrow: 1,
        },

        [`& .${classes.closeMenuButton}`]: {
            marginRight: 'auto', marginLeft: 0,
        }
    }));
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
        setAccount(true)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setAccount(false)
    };
    const handleNotiDialogClose = () => {
        setNotiDialogOpen(false)
    }
    const activetRoute = route => {
        return route === path;
    }
    const handleOnMouseEnter = (e) => {
        handleDrawerOpen()
    }
    const handleOnMouseLeave = (e) => {
        handleDrawerClose()
    }
    const handleLogout = (e) => {
        logout()
        history.push("/login")
        setAccount(false)
    }
    const handleCloseAllDrawers = (e) => {
        setMobileOpen(false)
        setOpen(false)

    }
    const getMenuForUser = () => {

        var usermenu = new Set()

        var mapping = {}
        mapping["erpcloud"] = []
        mapping["education"] = ["etl", "linkmanager", "data"]
        mapping["aicloud"] = ["rxa", "streamer"]
        mapping["sysadmin"] = ["etl", "linkmanager", "data", "reports", "controlcenter", "jobs", "files", "cxa", "thor", "rxa", "streamer"]


        userLevel.forEach(x => {
            mapping[x].forEach(y => usermenu.add(y))
            // usermenu.add(...mapping[x])
        })

        return [...usermenu]
    }
    const isValidMenu = (path) => {

        if (usermenu) return usermenu.includes(path)
        else return false
    }
    const drawer = (<Root onClick={handleCloseAllDrawers}>
        {isValidMenu("etl") ? <Link to="/etl" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/etl")}>
                <ListItemIcon>
                    <FontAwesomeIcon
                        sx={activetRoute("/etl") ? classes.iconActive : classes.iconDrawer}
                        icon={faCloudUploadAlt} size="lg"
                    />
                </ListItemIcon>
                <ListItemText primary="ETL Manager" />
            </ListItem>
        </Link> : <></>}
        {isValidMenu("linkmanager") ? <Link to="/linkmanager" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button color="primary" selected={activetRoute("/linkmanager")}>
                <ListItemIcon>
                    <FontAwesomeIcon
                        sx={{ color: "primary.main" }}
                        icon={faProjectDiagram} size="lg" />
                </ListItemIcon>
                <ListItemText primary="Link Manager" />
            </ListItem>
        </Link> : <></>}
        {isValidMenu("data") ?
            <Link to="/data" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <ListItem button selected={activetRoute("/data")}>
                    <ListItemIcon>

                        <FontAwesomeIcon
                            // className={activetRoute("/data") ? classes.iconActive : classes.iconDrawer}
                            icon={faSearch} size="lg" />
                    </ListItemIcon>
                    <ListItemText primary="Query Manager" />
                </ListItem>
            </Link> : <></>}
        {isValidMenu("reports") ? <Link to="/reports" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/reports")}>
                <ListItemIcon>
                    <FontAwesomeIcon
                        // className={activetRoute("/reports") ? classes.iconActive : classes.iconDrawer}
                        icon={faDigitalTachograph} size="lg" />
                </ListItemIcon>
                <ListItemText primary="Report Manager" />
            </ListItem>
        </Link> : <></>}
        {isValidMenu("controlcenter") ? <Link to="/controlcenter" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/controlcenter")}>
                <ListItemIcon>
                    {/*<Badge badgeContent={messages.length} max={10} color="primary">*/}
                    <FontAwesomeIcon
                        // className={activetRoute("/controlcenter") ? classes.iconActive : classes.iconDrawer}
                        icon={faSpaceShuttle} size="lg" />
                    {/*</Badge>*/}
                </ListItemIcon>
                <ListItemText primary="Control Center" />
            </ListItem>
        </Link> : <></>}
        {isValidMenu("jobs") ? <Link to="/jobs" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/jobs")}>
                <ListItemIcon sx={{
                    transform: "rotate(0deg)", display: "block"
                }}>
                    <FontAwesomeIcon
                        // className={`${activetRoute("/jobs") ? classes.iconActive : classes.iconDrawer} ${classes.rotated}`}
                        icon={faTasks} size="lg" />
                </ListItemIcon>
                <ListItemText primary="Job Manager" />
            </ListItem>
        </Link> : <></>}
        {isValidMenu("files") ? <Link to="/files" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/files")}>
                <ListItemIcon>
                    <FontAwesomeIcon
                        // className={activetRoute("/files") ? classes.iconActive : classes.iconDrawer}
                        icon={faFolderOpen} size="lg" />
                </ListItemIcon>
                <ListItemText primary="File Manager" />
            </ListItem>
        </Link> : <></>}
        {isValidMenu("cxa") ? <Link to="/cxa" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/cxa")}>
                <ListItemIcon>
                    <FontAwesomeIcon
                        // className={activetRoute("/cxa") ? classes.iconActive : classes.iconDrawer}
                        icon={faFilm} size="lg" />
                </ListItemIcon>
                <ListItemText primary="cxa" />
            </ListItem>
        </Link> : <></>}
        {isValidMenu("rxa") ? <Link to="/rxa" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/rxa")}>
                <ListItemIcon>
                    <FontAwesomeIcon
                        // className={activetRoute("/rxa") ? classes.iconActive : classes.iconDrawer}
                        icon={faTv} size="lg" />
                </ListItemIcon>
                <ListItemText primary="Kanal Dashboard" />
            </ListItem>
        </Link> : <></>}
        {isValidMenu("streamer") ? <Link to="/streamer" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/streamer")}>
                <ListItemIcon>
                    <FontAwesomeIcon
                        // className={activetRoute("/rxa") ? classes.iconActive : classes.iconDrawer}
                        icon={faCamera} size="lg" />
                </ListItemIcon>
                <ListItemText primary="Kamera Übertragung" />
            </ListItem>
        </Link> : <></>}
        <Divider style={{ marginTop: 5, marginBottom: 5, strokeWidth: 2 }} />

        <Link to="/help" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem button selected={activetRoute("/help")}>
                <ListItemIcon>
                    <FontAwesomeIcon
                        // className={activetRoute("/rxa") ? classes.iconActive : classes.iconDrawer}
                        icon={faInfoCircle} size="lg" />
                </ListItemIcon>
                <ListItemText primary="Kamera Übertragung" />
            </ListItem>
        </Link>
        {/*<Divider/>*/}


    </Root>);


    const UserMenu = () => {
        const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
        return (<div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                sx={{ color: theme.palette.light.main }}
                size="large"
                {...bindTrigger(popupState)}
            >
                <AccountCircle fontSize="medium" className="svg_icons" />

            </IconButton>

            <Menu {...bindMenu(popupState)}>
                <div style={{
                    display: 'flex', flexDirection: "column", alignContent: "center", alignItems: "center"
                }}>
                    <h3>Informationen:</h3>
                    <MenuItem> Benutzer: {getSession().username}</MenuItem>
                    <Divider />
                    <Divider />
                    <MenuItem onClick={handleClose}> {<SettingsIcon />}
                        <Link style={{ color: 'inherit', textDecoration: 'inherit' }}
                            to="/user">Profil bearbeiten</Link>
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        <GSButton onClick={handleLogout} icon={<ExitToAppIcon />} text="Logout" />
                    </MenuItem>
                </div>
            </Menu>
        </div>)
    }


    const ToolBarContent = () => {
        return <>
            <Link to={"/start"} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <Button>
                    <Hidden xsUp implementation="css">
                        <Typography sx={{ color: "light.main" }} variant="h5">
                            GSS VISION
                        </Typography>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Typography sx={{ color: "light.main" }} variant="h5">
                            GSS VISION
                        </Typography>
                    </Hidden>
                </Button>
            </Link>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
            </Typography>

            {getSession()?.levels.includes("education") ?
                <Link to={"/learn"} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                    <Button style={{ marginRight: 5 }} variant="contained" startIcon={<LocalLibrary />}>LEARN</Button>
                </Link> : <></>}

            {/*<Button variant="contained" onClick={e => setNotiDialogOpen(true)}>*/}
            {/*    <Notifications/>*/}
            {/*</Button>*/}

            <div>{localbroadcast ? <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (<React.Fragment>
                    <Button variant="contained"
                        style={{ marginLeft: 10, marginRight: 5 }} {...bindTrigger(popupState)}>
                        <VideoCameraBack />
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem>
                            <ListItemIcon>
                                <Crop32Icon fontSize="small" />
                            </ListItemIcon>
                            {getPeerconnections()?.target}
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <VideoCameraFrontIcon fontSize="small" />
                            </ListItemIcon>
                            {getPeerconnections()?.camera}</MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <MicIcon fontSize="small" />
                            </ListItemIcon>
                            {getPeerconnections()?.audio}</MenuItem>
                        <Button variant="contained" style={{ width: "95%", marginLeft: 5 }} color="primary"
                            onClick={e => {
                                var stream = getPeerconnections()

                                stream.socket.disconnect()
                                if (window.stream) {
                                    window.stream.getTracks().forEach(track => {
                                        track.stop();
                                    });
                                }
                                setPeerConnection(null)
                                setLocalBroadcast(false)
                                popupState.close()

                            }}>
                            <ListItemIcon>
                                <StopCircleIcon fontSize="small" />
                            </ListItemIcon>
                            Übertragung Beenden</Button>
                    </Menu>
                </React.Fragment>)}
            </PopupState> : <div />} </div>
            <div>
                {getSession() ? <UserMenu /> : <div>
                    <Link to={"/login"} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <GSButton icon={<LockOpenIcon />}
                            text="Bitte einloggen" />
                    </Link>
                </div>}
            </div>
        </>
    }

    return (
        <div
            className={classes.root}

        >
            {/* <CssBaseline /> */}

            {/* {isflutter ?  */}
            <MuiAppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <ToolBarContent />
                </Toolbar>
            </MuiAppBar>
            {/* : <>  <Hidden smUp implementation="css">
                    <MuiAppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                                size="large">
                                <MenuIcon />
                            </IconButton>
                            <ToolBarContent />
                        </Toolbar>
                    </MuiAppBar>
                </Hidden>

                    <Hidden smDown implementation="css">
                        <AppBar position="fixed" open={open} className={classes.appBar}>
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        marginRight: '36px', ...(open && { display: 'none' }),
                                    }}>
                                    <MenuIcon />
                                </IconButton>
                                <ToolBarContent />
                            </Toolbar>
                        </AppBar>
                    </Hidden></>} */}


            <nav className={classes.drawer}>

                {/* {isflutter ? */}
                <MuiDrawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}>
                    <IconButton
                        onClick={handleDrawerToggle}
                        className={classes.closeMenuButton}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                    {drawer}
                </MuiDrawer>
                {/* : <>
                        <Hidden smUp implementation="css">
                            <MuiDrawer
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}>
                                <IconButton
                                    onClick={handleDrawerToggle}
                                    className={classes.closeMenuButton}
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                                {drawer}
                            </MuiDrawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <Drawer variant="permanent" open={open}>
                                <DrawerHeader>
                                    <IconButton onClick={handleDrawerClose}>
                                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                    </IconButton>
                                </DrawerHeader>
                                {drawer}
                            </Drawer>
                        </Hidden></>
                } */}

            </nav>
            <Grid style={{
                marginTop: 60, width: "99vw",
                // background:" rgb(4,29,41)",
                // background: "linear-gradient(0deg, rgba(4,29,41,1) 0%, rgba(21,149,206,1) 100%)"

            }}>
                {/* <div className={classes.toolbar} /> */}
                <Grid item xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>

                    {content}
                </Grid>
            </Grid>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
};
export default ResponsiveDrawer;